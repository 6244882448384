<template>
  <!-- 咨询人数 -->
  <my-dialog title="设置咨询人数" @cancel="close" :visible="visible">
    <el-form ref="form" :model="form" label-width="200px">
      <el-form-item label="设置同时最多咨询人数">
        <el-input v-model="form.name" placeholder="请输入多少人同时进入咨询"></el-input>
        <span class="tips">（不包括已预约者，当面咨询治疗者不受此限制）</span>
      </el-form-item>

      <el-button type="primary" @click="setCurrPatient">确定</el-button>
    </el-form>
  </my-dialog>
</template>

<script>
  import MyDialog from "../../../../components/MyDialog";
  import {getInquiry, setCurrPatient} from "../../../../api/DoctorApi";

  export default {
    name: 'SetVisitorDialog',
    components: {MyDialog},
    data() {
      return {
        visible: false,
        form: {}
      }
    },

    methods: {

      /**
       * 展示信息
       */
      show() {
        this.visible = true;
        getInquiry().then(res => {
          this.form = res;
        });
      },

      /**
       * 关闭弹窗
       */
      close() {
        this.visible = false;
        this.form = {}
      },

      /**
       * 设置内容
       */
      setCurrPatient() {
        setCurrPatient(this.form).then(() => {
          this.$message.success("设置成功");
          this.close();
        })
      }
    }
  }
</script>

<style scoped lang="scss">

  .el-form {
    margin-top: 40px;
    padding: 0 20px;
    text-align: center;

    .el-button {
      min-width: 120px;
      margin-left: 10px;
    }

    .tips {
      font-size: 12px;
      color: $red;
    }
  }

</style>
