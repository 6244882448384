<template>
  <my-dialog title="每个病人每次咨询时间设置" :visible="visible" @cancel="close" width="800px">
    <el-slider
        v-model="value"
        :format-tooltip="formatTooltip"
        :max="600"
        :marks="marks">
    </el-slider>

    <div class="tips">
      最晚至晚7点，必须第二次交费
    </div>

    <div class="text">
      （您已将对每位病人的每次咨询时间设定为 {{ formatTooltip(this.value) }}）
    </div>

    <div class="button">
      <el-button type="primary" @click="setDuration">确定</el-button>
    </div>

    <div class="content">
      请调整设定对每名病人每次咨询的时间，设置最低应不少于40分钟，系统默认为1小时，根据个人习惯及效率，都认为正常，（不含取药、送药时间）。当咨询时间达到设定的时间时，将会视为开始第二次咨询，提醒病人需再次支付咨询费，具体是否需要交费，由医生根据弹出的对话框选择决定。最晚至每晚7点，则必须第二次交费，否则无法继续咨询。
    </div>
  </my-dialog>
</template>

<script>
  import MyDialog from "../../../../components/MyDialog";
  import {getInquiry} from "../../../../api/DoctorApi";

  /**
   * 已知： slider 长度为100， 合计10小时， 约600分钟， 则每个1表示6分钟。
   * 40分钟则是 40/6 = 6.67
   *
   *
   */

  export default {
    name: 'SetDurationDialog',
    components: {MyDialog},
    data() {
      return {
        visible: false,
        value: 60,
        marks: {
          0: '0分钟',
          60: '1小时',
          600: '10小时',
          40: {
            style: {
              color: '#1989FA'
            },
            label: this.$createElement('strong', '40分钟')
          }
        }
      }
    },

    methods: {

      /**
       * 展示医生的每次咨询病人时长设定
       */
      show() {
        this.visible = true;
        getInquiry().then(res => {
          this.value = res.maxTime || 40
        })
      },


      /**
       * 关闭弹窗
       */
      close() {
        this.visible = false;
      },

      /**
       * 设置操作
       */
      setDuration(){
        setDuration(this.value).then(() => {
          this.$message.success("设置成功");
          this.close();
        })
      },

      /**
       * 格式化显示
       * @param val
       * @returns {string}
       */
      formatTooltip(val) {
        if (val < 60) {
          return val + '分钟';
        } else {
          let hour = Math.floor(val / 60);
          let minute = val - 60 * hour;
          if (minute === 0) {
            return hour + "小时"
          } else {
            return hour + '小时' + minute + '分钟'
          }
        }

      }
    }
  }
</script>

<style scoped lang="scss">

  .el-slider {
    margin-top: 40px;
  }

  /deep/ .el-slider__marks-text {
    font-size: 12px;
    width: 44px;
  }

  .tips {
    color: $red;
    text-align: right;
    font-size: 12px;
    margin-top: 20px;
  }

  .text {
    margin: 40px 20px;
    font-weight: 500;
  }

  .button {
    text-align: center;

    .el-button {
      min-width: 120px;
    }
  }

  .content {
    height: 100px;
    margin: 20px;
    border: 2px solid $red;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #FEF6F4;
    color: $red;
    padding: 20px;

  }

</style>
