<template>
  <my-dialog :visible="visible" title="新增咨询病人" @cancel="close" footer></my-dialog>
</template>

<script>
  import MyDialog from "../../../../components/MyDialog";

  export default {
    name: 'AddPatientDialog',
    components: {MyDialog},
    data() {
      return {
        visible: false,
      }
    }, 
    methods: {
      show() {
        this.visible = true;
      },

      close(){
        this.visible = false;
      }
    }
  }
</script>

<style scoped>

</style>
