<template>
	<el-dialog  :append-to-body="true" :visible="visible" title="上传签名"   @close='close()'   width="900px" 
	style="margin-top:-13px!important; text-align: center;   ">  
	  
		<el-upload
		   class="avatar-uploader"
		   action="#"
		   :show-file-list="false"
		   accept="image/*"
		   :http-request="upload" 
		 >
			    <img v-if="imageUrl" :src="imageUrl" class="avatar">
			    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			  </el-upload>
		
 
 </el-dialog>
  

</template>

<script>
	
	import $ from "jquery"
    import axios from "axios"; 
	import MyDialog from "../../../../components/MyDialog";  
	export default {
		
		name: 'SetWriteNameImgDialog',
		 
		
		components: {
			MyDialog
		},
		data() {
			return {
				imageUrl:  '', 
				visible: false,
				 
			
			}
		}, 
		created(){    
			 
				
				const params = new URLSearchParams();
					params.append('uid', sessionStorage.getItem('uid')); 
					 
					axios.post(this.apiUrl+'/api/user/getInfo',params)
					.then((response) => {   
					 if( response.data.data.upload_autograph){ 
						 	this.imageUrl = this.apiUrl+response.data.data.upload_autograph;
					 }
					
					
						
						
					}) 
					.catch(function(error) {
					  console.log(error);
					}); 
			
		}, 
		
 
		
		methods: {
			
			 
			
			show(did) { 
				 
				
				this.visible = true;
			},
			
			close() {
				this.visible = false;
			}
			,upload(e) {
		  //这是参数配置，可以忽略
		    const tthash = window.btoa(
		        window
		            .btoa(encodeURI(location.hash.replace(	'#/', '') ?? ''))
		            .split('')
		            .reverse()
		            .join('')
		    )
		    //使用FormData 的最大优点就是可以异步上传二进制文件。
		    //而图片，文件之类的文件是二进制，接口接收参数也是接收的二进制
		    //可以先通过new关键字创建一个空的 FormData 对象，然后使用 append() 方法向该对象里添加字段
		    //（字段的值可以是一个 Blob 对象，File对象或者字符串，剩下其他类型的值都会被自动转换成字符串）。
				
		    //也可以用一个已有的form元素来初始化FormData对象
			
			this.getBase64(e.file).then((res) => { 
					
					const formData = new FormData() 
							 
					//这里是把上传接口所有的参数都append到formData中
					formData.append('img', res)
					formData.append('uid', sessionStorage.getItem('uid'))
					
					const config = {
					    headers: {
					        'Content-Type': 'multipart/form-data'
					    }
					}
					
					//接口调用
					axios.post(this.apiUrl+'/api/user/upImgAutograph', formData, config).then((res) => {
					    this.imageUrl = this.apiUrl+res.data.data.pic; 
						
						//this.from.logo = res.data.data.filename
					})
					
					
			    })
				
				
		   
		},
		
		getBase64(file) {
		            return new Promise(function(resolve, reject) {
		                const reader = new FileReader()
		                let imgResult = ''
		                reader.readAsDataURL(file)
		                reader.onload = function() {
		                    imgResult = reader.result
		                }
		                reader.onerror = function(error) {
		                    reject(error)
		                }
		                reader.onloadend = function() {
		                    resolve(imgResult)
		                }
		            })
		        },
		}
		
			
	}
</script>


<style scoped lang="scss">
	
	.imgs li img{
			width: 60px;
		}
		li{
		            list-style-type:none;
		            width:20%;
					height: 20%;
					//background-color: rosybrown;
					float: left;
					text-align: center;
		} 
		
		li img{
			width: 35px;
		}
	
		#tab-4{
			margin-top: 300px!important;
		}
		.el-upload--text{
			
			    border: 1px dashed #d9d9d9;
		}
		.avatar-uploader .el-upload {
		    border: 1px dashed #d9d9d9;
		    border-radius: 6px;
		    cursor: pointer;
		    position: relative;
		    overflow: hidden;
		  }
		  .avatar-uploader .el-upload:hover {
		    border-color: #409EFF;
		    border: 1px dashed #d9d9d9;
		  }
		  .avatar-uploader-icon {
		    font-size: 28px;
		    color: #8c939d;
		    width: 178px;
		    height: 178px;
		    line-height: 178px;
		    text-align: center;
		  }
		  .avatar {
		    width: 178px;
		    height: 178px;
		    display: block;
		  }
		  
	.center {
	  width: 100%;
	  min-height: 1050px;
	  background-color: #ffffff;
	  box-shadow: white;
	
	  .el-aside {
	    .title {
	      height: 100%;
	      -webkit-writing-mode: vertical-lr;
	      writing-mode: vertical-lr;
	      text-align: center;
	      display: block;
	      margin: 14px;
	      font-size: 18px;
	      font-weight: bold;
	      letter-spacing: 10px
	
	    }
	  }
	
	  .el-main {
	    .title {
	
	      small {
	        margin-left: 10px;
	        font-weight: normal;
	      }
	    }
	
	    .el-tabs {
	      height: calc(100% - 60px);
	
	      /deep/ .el-tabs__header {
	        width: 150px;
	      }
	
	      /deep/ .el-tabs__item {
	        margin: 50px 0 50px -1px;
	        color: #000 !important;
	        text-align: center;
	
	        &.is-active {
	          border-left: 4px solid $green;
	        }
	      }
	
	      .el-image {
	        border: 1px solid #979797;
	        margin-top: 20px;
	        width: 150px;
	        height: 150px;
	      }
	
	      .button {
	        margin-top: 150px;
	        padding-left: 50px;
	
	        .el-button {
	          display: block;
	          margin: 10px 0;
	          width: 120px;
	        }
	      }
	
	      .address {
	        & + .el-input {
	          margin-top: 10px;
	        }
	
	        .el-col {
	          padding: 0 5px 0 0;
	
	          &:last-child {
	            padding: 0;
	          }
	        }
	      }
	
	      /deep/ .el-radio__label {
	        font-size: 12px;
	      }
	    }
	  }
	
	
	}
	
	.el-select {
	  width: 100%;
	}
	
	
	
	.tableBox {
		margin-top: 50px;
		position: relative;
		/* background-color: red; */
	}

	.line {
		position: absolute;
		/* position:fixed; */
		z-index: 9;
		top: 0;
		left: 0;
		height: 1px;
		width: 110px;
		background-color: black;
		transform-origin: left;
		transform: rotate(24.2deg);
	}

	.el-slider {
		margin-top: 40px;
	}

	/deep/ .el-slider__marks-text {
		font-size: 12px;
		width: 44px;
	}

	.tips {
		color: $red;
		text-align: right;
		font-size: 12px;
		margin-top: 20px;
	}

	.text {
		margin: 40px 20px;
		font-weight: 500;
	}

	.button {
		text-align: center;

		.el-button {
			min-width: 120px;
		}
	}

	.content {
		height: 100px;
		margin: 20px;
		border: 2px solid $red;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background: #FEF6F4;
		color: $red;
		padding: 20px;

	}
</style>
