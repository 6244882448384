<template>
  <el-card class="mine-service">
    <div slot="header">
      <h3>医疗服务</h3>
    </div>
    <div>
		
<!--  <el-button type="primary" @click="addPatient">增加咨询病人</el-button>
      <el-button type="primary" @click="setVisitor">设置同时咨询人数</el-button>
      <el-button type="primary" @click="onSpot">即珍</el-button>
      <el-button type="primary" @click="setDuration">每个病人每次咨询时间设置</el-button> -->

	
 
	  <el-button type="primary" @click="setWriteNameImg"   >上传签名</el-button> 
		  
	  <el-button type="primary" @click="setWriteName" >手写签名</el-button> 
		  
	  <el-button type="primary" @click="getCase">官方病例模板</el-button>
	  
      <el-button type="primary" @click="setTtreatmentTime">预约咨询设置</el-button>
 

      <set-write-name-img-dialog ref="setWriteNameImg"></set-write-name-img-dialog>

      <set-write-name-dialog ref="setWriteName"></set-write-name-dialog>
	  
      <get-case-template2-dialog ref="GetCaseTemplate2"></get-case-template2-dialog>
      <add-patient-dialog ref="addPatient"></add-patient-dialog>
      <set-visitor-dialog ref="setVisitor"></set-visitor-dialog>
      <set-duration-dialog ref="setDuration"></set-duration-dialog>
      <set-ttreatment-time-dialog ref="setTtreatmentTime"></set-ttreatment-time-dialog>
	    
    </div>
  </el-card>
</template>

<script>
  import axios from "axios";  
  
  import SetWriteNameImgDialog from "./components/SetWriteNameImgDialog"; 
  import SetWriteNameDialog from "./components/SetWriteNameDialog"; 
  
  import GetCaseTemplate2Dialog from "./components/GetCaseTemplate2Dialog"; 
  import AddPatientDialog from "./components/AddPatientDialog";
  import SetVisitorDialog from "./components/SetVisitorDialog";
  import SetDurationDialog from "./components/SetDurationDialog";
  import SetTtreatmentTimeDialog from "./components/SetTtreatmentTimeDialog";
  import {setOnSpot} from "../../../api/DoctorApi";
  export default {
    name: "ServiceIndex",
    components: {SetWriteNameImgDialog,SetWriteNameDialog,GetCaseTemplate2Dialog,SetDurationDialog, SetVisitorDialog, AddPatientDialog,SetTtreatmentTimeDialog},
    data(){
      return {
		imageUrl:'',
      }
    },

    methods:{
		
				
				
			submit(){
				//alert(3);
			},
			/**
			* 签名上传
			*/
			setWriteNameImg(){ 
				this.$refs['setWriteNameImg'].show();
			},
			
			
			/**
			* 签名设置
			*/
			setWriteName(){ 
				this.$refs['setWriteName'].show();
			},
			
			/**
			* 官方病例模板
			*/
			getCase(){  
				this.$refs['GetCaseTemplate2'].show();
			},
			
			
			
	 /**
	 * 看病预约设置
	 */
	setTtreatmentTime(){ 
		this.$refs['setTtreatmentTime'].show();
	},

      /**
       * 增加咨询病人
       */
      addPatient(){
        this.$refs['addPatient'].show();
      },

      /**
       * 设置咨询者人数
       */
      setVisitor(){
        this.$refs['setVisitor'].show();
      },

      /**
       * 即诊设置
       */
      onSpot(){
        this.$confirm('确认要开始即诊?', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          setOnSpot().then(() => {
            this.$message.success("设置成功，两小时后请再次确认");
          })
        }).catch(() => {});
      },

      /**
       * 设置每个病人每次预约时间
       */
      setDuration(){
        this.$refs['setDuration'].show();
      }
    }
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    h3 {
      margin: 0px;
      padding: 0px;
    }

    .el-button {
      margin: 20px 40px;
      padding: 10px 20px;
      min-width: 100px;
    }
  }


</style>
